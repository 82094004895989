import React from "react"
import NavigationNode from './navigation-node'

export default ({listItem: {locatable: {title, model, heroImage}, locatable, titleOverride}}) => {
  const appliedHeroImageUrl = heroImage ? (heroImage.url && heroImage.url.includes("?auto=format") ?
    heroImage.url.replace("?auto=format", "") : heroImage.url) : null;
  return (
    <NavigationNode locatable={locatable} className="uk-link-reset" >
      <div className="uk-flex uk-flex-middle uk-flex-center uk-flex-column">
        <div>
          <img alt={title} data-src={heroImage && `${appliedHeroImageUrl}?fit=crop&w=500&h=500`} className="uk-animation-fade card-round" width="500" height="500" uk-img="" />
        </div>
        <h5 className="uk-text-primary uk-margin-small-top uk-text-center">{titleOverride || title}</h5>
      </div>
    </NavigationNode>
  )
}
