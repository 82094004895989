import React from "react"
import Img from 'gatsby-image'
import * as classes from './sponsor.module.css';

export default (props) => (
  <section className="uk-section">
    <div className="uk-container">
      <h3 className="uk-heading-line uk-text-center uk-text-uppercase text-saddle"><span>{props.widget.title}</span></h3>
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" uk-slider="sets: true; autoplay: true; autoplay-interval: 8000;">
        <div className={`uk-slider-container uk-slider-container-offset ${props.widget.sponsors.length < 5 ? "uk-flex uk-flex-center" : ""}`}>
          <ul className="uk-slider-items uk-grid uk-grid-large uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-5@m uk-child-width-1-6@l">
            {props.widget.sponsors.map((sponsor, index) => (
              <li className={`uk-flex uk-flex-middle ${props.widget.sponsors.length === 1 ? classes.slider_item_outer : ""} ${props.widget.sponsors.length < 5 ? classes.flexItem : ""}`} key={index} style={{minHeight: 200}}>
                <a className={`uk-flex uk-flex-middle uk-height-1-1 uk-width-1-1 uk-position-absolute ${classes.slider_item}`} style={{maxWidth: "100%"}} href={sponsor.link} target="_blank">
                  {sponsor.image && (
                    <Img className={"uk-display-block uk-width-1-1"} fluid={sponsor.image.fluid} />
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
)
