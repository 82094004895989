import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Explorer from "../components/explorer"
import HeroTitleBlock from "../components/hero-title-block"
import DiscoverMore from "../components/discover-more"
import ModifiedHelmet from '../components/modified-helmet'
import Sponsor from "../components/sponsor"
import {useAllEventData} from '../hooks/events'
import pluralize from 'pluralize'


export default ({ data: { datoCmsWebsiteLayout, allDatoCmsCategory, datoCmsSubcategory: {title, id, category, landingPageHeader, landingPageDiscoverMore, seoMetaTags, icon, sponsors} } }) => {

  const categoryObject = allDatoCmsCategory.edges.flatMap(e => e.node).find(c => c.title === pluralize(category))
  let explorer = {};
  explorer.buttonLabel = categoryObject.buttonLabel
  explorer.page = categoryObject.page
  explorer.tabs = [{title: title, visibleItems: 12, list: { __typename: "DatoCmsDynamicList", scopes: [{__typename: "DatoCmsSubcategory", id: id, category: category }] }}]
  const isRide = category === "Ride"
  const isThrillVille = title.toLowerCase().includes("thrillville")
  const isAlcohol = title === "Beer, Wine & Spirits"
  if (isRide || isAlcohol || isThrillVille) {
    const events = useAllEventData()
    explorer.tabs.push({title: "Hours", list: {__typename: "Hours", events, isAlcohol, isRide, isThrillVille}})
  }

  return (
    <Layout>
      <ModifiedHelmet seo={seoMetaTags} />
      {landingPageHeader && (
        <HeroTitleBlock widget={landingPageHeader} />
      )}
      {landingPageDiscoverMore && (
        <DiscoverMore widget={landingPageDiscoverMore}  />
      )}
      <Explorer widget={explorer} />
      {(sponsors || datoCmsWebsiteLayout.sponsors) && (
        <Sponsor widget={sponsors || datoCmsWebsiteLayout.sponsors}/>
      )}
    </Layout>
  )
}

export const query = graphql`
  query SubcategoryQuery($id: String!) {
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    allDatoCmsCategory {
      edges {
        node {
          title
          buttonLabel
          page {
            __typename
            ... on DatoCmsCategory {
              id
            }
            ... on DatoCmsPage {
              id
            }
          }
        }
      }
    }
    datoCmsSubcategory(id: { eq: $id }) {
      id
      title
      category
      landingPageHeader {
        ...HeroTitleBlock
      }
      landingPageDiscoverMore {
        ...DiscoverMore
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      icon {
        title
        set
      }
      sponsors {
        ...Sponsor
      }
    }
  }
`
