import React from "react"
import ResultFeed from './result-feed'
import ResultCard from './result-card'
import MatrixCard from './matrix-card'
import EventHours from './event-hours'
import NavigationNode from './navigation-node'
import { StaticQuery, graphql } from "gatsby"
import pluralize from 'pluralize'
import moment from "moment"

export default class Explorer extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      filterScope: {}
    }
  }
  componentDidMount() {
    try {
      this.UIkit = require('uikit/dist/js/uikit')
    } catch (e) {
      console.error(e)
    }
  }

  onFilterClick = (filter) => {
    return (e) => {
      e.preventDefault();
      this.setState({ filterScope: filter})
      this.UIkit.dropdown("#filterDropdown").hide();
    }
  }

  filterLocatablesByScope = (locatables, scopes) => {
    return locatables.filter((locatable) => {
      return scopes.every(scope => {
        switch (scope.__typename) {
          case "DatoCmsEvent":
            return locatable.events && locatable.events.find((e => e.id === scope.id))
          case "DatoCmsCategory":
            return pluralize(locatable.model.apiKey) === scope.title.toLowerCase()
          case "DatoCmsSubcategory":
            return locatable.subcategories.find((sb => sb.id === scope.id))
          case "DatoCmsUniversalTag":
            return locatable.universalTags.find((sb => sb.id === scope.id))
          case "DatoCmsKeyword":
            return locatable.keywords.find((k => k.id === scope.id))
          default:
            return true
        }
      })
    })
  }

  isSchedule = (scopes) => {
    return scopes.find((scope) => {
      const isCategory = "DatoCmsCategory" === scope.__typename
      const isSubcategory = "DatoCmsSubcategory" === scope.__typename
      return (isSubcategory && scope.category === "Activity") || (isCategory && scope.title === "Activities")
    })
  }

  render() {
    const {widget: {title, backgroundImage, tabs, buttonExternalLink, page, buttonLabel }} = this.props
    return (
      <StaticQuery
        query={graphql`
          query allLocatablesQuery {
            allDatoCmsActivity {
              edges {
                node {
                  ...ActivityFields
                }
              }
            }
            allDatoCmsBuilding {
              edges {
                node {
                  ...BuildingFields
                }
              }
            }
            allDatoCmsDeal {
              edges {
                node {
                  ...DealFields
                }
              }
            }
            allDatoCmsRide {
              edges {
                node {
                  ...RideFields
                }
              }
            }
            allDatoCmsRide {
              edges {
                node {
                  ...RideFields
                }
              }
            }
            allDatoCmsService {
              edges {
                node {
                  ...ServiceFields
                }
              }
            }
            allDatoCmsVendor {
              edges {
                node {
                  ...VendorFields
                }
              }
            }
          }
        `}
        render={(data) => {
          let locatables = Object.values(data).flatMap((l => l.edges.flatMap((edge) => edge.node)))
          return (
            <section style={{backgroundSize: "100%"}} className="uk-section uk-section-small uk-background-muted uk-background-bottom-center uk-background-blend-multiply uk-background-norepeat" data-src={backgroundImage && `${backgroundImage.url}?auto=compress`} uk-img="">
              <div className="uk-container uk-container-xsmall uk-padding-remove">
                {
                  title && (
                    <h3 className="uk-text-secondary uk-text-center uk-text-uppercase">{title}</h3>
                  )
                }
                <div className="uk-card uk-card-default background-transparent">
                  <ul uk-tab="" className="uk-child-width-expand uk-flex-bottom uk-background-default">
                    { tabs.map((tab, index) => {
                      if (tab.list && tab.list.__typename === "FilterDropdown") {
                        return (
                          <li key={index} >
                            <a href={`#${tab.title}`}>Filter{this.state.filterScope.title && ` by ${this.state.filterScope.title}`} <span className="uk-margin-small-left" uk-icon="icon: triangle-down"></span></a>
                            <div id="filterDropdown" className="uk-light uk-background-secondary" uk-dropdown="mode: click; delay-hide: 0;pos: bottom-justify" style={{ zIndex: 2 }}>
                              <ul className="uk-nav uk-dropdown-nav uk-list uk-padding-remove ">
                                {
                                  tab.list.filters && tab.list.filters.sort((a,b) => {
                                    return (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0;
                                  }).map((filter, i) => {
                                    return (
                                      this.filterLocatablesByScope(locatables, tab.list.scopes.concat(filter)).length > 0 &&
                                        <li key={i}><a href={`#${filter.title}`} className="uk-text-primary uk-text-bold uk-link-list" onClick={this.onFilterClick(filter)}>{filter.title}</a></li>
                                    );
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                        )
                      } else {
                        return (
                          <li key={index}><a href={`#${tab.title}`}>{tab.title}</a></li>
                        )
                      }
                    })}
                  </ul>
                  <ul className="uk-switcher padding@s">
                    { tabs.map( (tab, index) => {
                      if (tab.list) {
                        switch (tab.list.__typename) {
                          case "Hours":
                            const {events, isThrillVille, isRide, isAlcohol} = tab.list
                            return (
                              <li key={index}>
                                <EventHours events={events} isThrillVille={isThrillVille} isRide={isRide} isAlcohol={isAlcohol} />
                              </li>
                            )
                          case "FilterDropdown":
                          case "DatoCmsDynamicList":
                            let addFilter = tab.list.__typename === "FilterDropdown"
                            let tabLocatables = this.filterLocatablesByScope(locatables, addFilter ? tab.list.scopes.concat(this.state.filterScope) : tab.list.scopes)
                            return <li key={index}><ResultFeed visibleItems={tab.visibleItems || 12} showUnscheduledActivities={tab.showUnscheduledActivities} locatables={tabLocatables} isSchedule={this.isSchedule(tab.list.scopes)}/></li>
                          case "DatoCmsSelectList":
                            switch (tab.layout) {
                              case "Cards":
                                return (
                                  <li key={index}>
                                    {tab.list.items.map(item => (
                                      <>
                                        {item.locatable && (
                                          <ResultCard locatable={item.locatable}/>
                                        )}
                                      </>
                                    ))}
                                  </li>
                                )
                              case "Image matrix":
                                return (
                                  <li key={index}>
                                    <div className="uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-flex-center" uk-grid="">
                                      {tab.list.items.map(item => (
                                        <>
                                          {
                                            item.locatable && (
                                              <MatrixCard listItem={item}/>
                                            )
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </li>
                                )
                              default:
                                return <li key={index}>Something went wrong</li>
                            }
                          default:
                            return <li key={index}>{tab.list.__typename}</li>
                        }
                      } else {
                        return <p key={index}>Select a list</p>
                      }
                    })}
                  </ul>
                </div>
                {
                  (page || buttonExternalLink) && (
                    <div className="uk-flex uk-flex-center uk-margin-top">
                      <NavigationNode locatable={page} node={{externalLink: buttonExternalLink}} className="uk-button uk-button-primary">{buttonLabel}</NavigationNode>
                    </div>
                  )
                }
              </div>
            </section>
          )
        }}
      />
    )
  }
}
